import React, { useState, useEffect } from 'react';
import './styles.css'

const LotusFlower = (props) => {
    return(
        <p className="Snowflake" id={`item${props.id}`} style={props.style}>
           <img src="./img/lotus.png" alt="petals" />
        </p>
    )
 }

 export default LotusFlower;