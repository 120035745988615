import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import HowCPF from "./Pages/Home/Informations/howCPF";
import HowLicense from "./Pages/Home/Informations/howLicense";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar home={true} />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/como-tirar-segunda-via-cpf-em-sp" element={<HowCPF />}></Route>
            <Route path="/como-tirar-sua-primeira-habilitacao" element={<HowLicense/>}></Route>
            <Route path="*" element={<div>404 Not Found</div>}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
