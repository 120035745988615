import Navbar from "../Navbar";
import Footer from "../Footer";
import './styles.css'

const HowLicense = () => {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
        <>
        <Navbar home={false} />

            <div className="container-info">

                <br/>
                <div className="flex-center license-h">
                    <h2 className="blue-text">Quer tirar sua primeira habilitação e não sabe pode onde começar?</h2>
                    <h2 className="blue-text">A Rádio Flor de Lótus te ajuda! Veja o passo-a-passo abaixo:</h2>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Para tirar a primeira habilitação também chamada de Permissão para dirigir, o que é preciso?</p>
                    </div>
                    
                    <div>
                        <p>É necessário ter 18 anos, saber ler e escrever, precisa ter RG ou outro documento de identidade, CPF próprio e comprovante de
                        residência. Se você cumpre estas exigências já pode fazer seu cadastro gratuito no portal do Detran da sua cidade, informe seus
                        dados e agende sua coleta de biometria em uma unidade do Detran ou do Poupatempo da cidade onde você mora!</p>
                    </div>

                    <div>
                        <p>
                        Biometria é a coleta eletrônica de suas digitais, da sua fotografia e de sua assinatura. Depois da biometria é hora de agendar o
                        exame médico e o psicológico, feitos apenas por profissionais credenciados.
                        </p>
                    </div>
                </div>

                <div className="paragraph">

                    <p className="bold blue-text-inside">
                        O valor dos exames é pago diretamente ao médico e ao psicólogo e não ao Detran.
                    </p>

                </div>

                 <div className="paragraph">

                    <p>
                        Se você for reprovado em um dos exames, pode marcar outro pagando nova taxa. Se você foi aprovado no exame médico e
                        psicológico é hora de procurar uma Autoescola!

                    </p>

                </div>

                <div className="paragraph">

                    <p className="bold blue-text-inside">
                        Preste atenção pois umas só dão aulas teóricas, outras somente aulas práticas e tem aquelas que oferece o pacote
                        completo.
                    </p>

                </div>

                <div className="paragraph">

                    <p className="red-text">
                        A lista com as autoescolas credenciadas em todo estado você acessa no portal do Detran. 
                    </p>

                </div>


                <div className="paragraph">

                    <p>
                        Antes de contratar o serviço não esqueça de pesquisar a opinião de ex-alunos e possíveis reclamações nos órgãos de defesa do
                        consumidor. 
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Na autoescola você terá de cumprir 45 horas de aulas teóricas. Vai estudar legislação do trânsito, primeiros- socorros, direção
                        defensiva, mecânica básica de veículos e questões ambientais. Quando terminar as aulas teóricas a autoescola emitirá um
                        certificado de conclusão do curso e com ele, você ou a autoescola poderão marcar a prova teórica. Para isso, vá ao posto do
                        Detran de sua cidade com o certificado de conclusão de curso, comprovante de pagamento da prova teórica e documento de
                        identidade com foto.
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        DICA: Antes da prova você pode treinar fazendo o simulado disponível no portal do Detran. 
                    </p>

                </div>

                <div className="paragraph">

                    <p className="red-text">
                        O resultado da prova teórica pode ser consultado nos serviços online no portal do Detran.  
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Assim que terminar o curso teórico, antes de fazer a prova, você pode iniciar o treinamento do simulador de direção veicular,
                        que é o primeiro passo para as aulas práticas.  
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Você precisa fazer no mínimo 5 aulas no simulador e se quiser pode fazer até 8 aulas no equipamento que recria as mesmas
                        condições do trânsito nas ruas. As horas de treinamento do simulador serão descontadas do total de 25 aulas práticas exigidas
                        pela legislação do trânsito. Terminou as aulas do simulador, agora sim é hora de começar o treinamento nas ruas.   
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Se você fez 5 aulas no simulador, terá de fazer 16 aulas práticas diurnas e 4 noturnas. Quem fez 8 aulas no simulador, vai fazer
                        16 aulas diurnas e 1 noturna nas ruas.
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Quando você concluir as aulas práticas, a autoescola deverá marcar sua prova de direção. Neste caso só a autoescola pode
                        agendar o exame, já que você vai usar o veículo dela.
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Se você faltar, o Detran não cobrará outra taxa, só se você não passar será cobrado.
                    </p>

                </div>

                <div className="paragraph">

                    <p>
                        Mas se foi aprovado na prova prática, <b className="red-text">PARABÉNS!!</b> Você já pode receber sua permissão para dirigir, sua habilitação provisória e
                        para isso, você deve pagar a taxa de emissão do documento.
                    </p>

                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold blue-text-inside">DICAS:</p>
                    </div>

                    <div>
                        <p>- Antes de fechar negócio com a autoescola, verifique no site do Detran se ela não está bloqueada.</p>
                    </div>

                    <div>
                        <p>- Exija contrato de prestação de serviços, detalhando o que está incluso no pacote.</p>
                    </div>

                    <div>
                        <p>- Algumas autoescolas cobram taxa para reagendar provas, o que NÃO é cobrado pelo Detran.</p>
                    </div>

                    <div>
                        <p>- Consulte o valor das taxas oficiais para os serviços de habilitação no portal do Detran: <a href="https://www.detran.sp.gov.br" target="_blank">www.detran.sp.gov.br</a>
                        </p>
                    </div>

                    <div>
                        <p>- Não aceite ofertas com aprovação garantida ou dispensas de aulas ou exames. Isso é CRIME. A responsabilidade é tanto de
                        quem oferece, como de quem aceita a facilidade.</p>
                    </div>

                    <div>
                        <p>- Se suspeitar ou presenciar qualquer irregularidade, DENUNCIE na ouvidoria do Detran. Seus dados serão mantidos em sigilo.
                        </p>
                    </div>

                </div>










                
                <div className="flex-center gap-top">
                    <h2 className="blue-text">"Boa Sorte! Rádio Flor de Lótus sempre com você!"</h2>
                </div>

            </div>

        <Footer />
        
        </>
    )
}

export default HowLicense;