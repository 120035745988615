export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      {/* <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div> */}
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="skills-section--heading">Sobre Nós</h1>
          <p className="hero--section-description">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
          </p>
        </div>
      </div>
    </section>
  );
}
