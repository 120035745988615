export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
        
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Rádio flor de Lótus</span>{" "}
          </h1>
          <p className="hero--section-description">
            {/* <h2>Música informação e solidariedade !</h2> */}
          </p>

        </div>
     
      </div>

      <div className="hero--section--content--box">
        <iframe src="https://radioetvflordelotus.com.br/" 
          frameborder="0" 
          width="100%" 
          height="450" 
          allowfullscreen>
        </iframe>
      </div>
     
   
    </section>
  );
}
