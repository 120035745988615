import Navbar from "../Navbar";
import Footer from "../Footer";
import './styles.css'

const HowCPF = () => {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
        <>
        <Navbar home={false} />

            <div className="container-info">
              

                <br/>
                <div className="flex-center">
                    <h2 className="blue-text">Como tirar segunda via do CPF em São Paulo? Veja abaixo:</h2>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 1: Documentos Necessários</p>
                    </div>
                    
                    <div>
                        <p>- Certidão de nascimento (para menores de 16 anos) ou RG (para maiores de 16 anos)</p>
                    </div>

                    <div>
                        <p>- Título de eleitor (opcional)</p>
                    </div>

                    <div>
                        <p>- Comprovante de residência</p>
                    </div>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 2: Acesso ao Site da Receita Federal</p>
                    </div>

                    <div>
                        <p>- Acesse o site da Receita Federal do Brasil <a href="https://www.gov.br/receitafederal" target="_blank">(www.gov.br/receitafederal)</a> e clique na opção "CPF" no
                        menu.</p>
                    </div>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 3: Preenchimento do Formulário Online</p>
                    </div>

                    <div>
                        <p>- Clique em "Inscrição" e preencha o formulário com seus dados pessoais, como nome
                        completo, data de nascimento, nome da mãe, etc.
                        </p>
                    </div>

                    <div>
                        <p>- Insira os dados do documento utilizado (RG ou certidão de nascimento) e do comprovante de
                        residência.
                        </p>
                    </div>

                    <div>
                        <p>- Após preencher todos os campos, clique em "Enviar".</p>
                    </div>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 4: Geração do Comprovante</p>
                    </div>

                    <div>
                        <p>- Após o envio dos dados, será gerado um número de protocolo.</p>
                    </div>

                    <div>
                        <p>- Imprima o comprovante de inscrição com esse número.</p>
                    </div>
                </div> 

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 5: Pagamento da Taxa</p>
                    </div>

                    <div>
                        <p>- Antes de comparecer ao atendimento presencial, realize o pagamento da taxa no valor de R$
                        7,00 em uma agência dos Correios, Banco do Brasil ou Caixa Econômica Federal.</p>
                    </div>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 6: Atendimento Presencial (opcional)</p>
                    </div>

                    <div>
                        <p>- Agende um horário em uma agência dos Correios, Banco do Brasil ou Caixa Econômica Federal
                        se preferir atendimento presencial.
                        </p>
                    </div>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 7: Comparecimento à Agência Escolhida</p>
                    </div>

                    <div>
                        <p>- Leve o comprovante de inscrição gerado no passo anterior, documento de identificação original
                        e o comprovante de residência.
                        </p>
                    </div>
                </div>

                <div className="paragraph">
                    <div>
                        <p className="bold">Passo 8: Conclusão do Processo</p>
                    </div>

                    <div>
                        <p>- O atendente irá finalizar o processo e informar o número do seu CPF.</p>
                    
                    </div>

                    <div>
                        <p>- Você receberá um cartão provisório com seu CPF e o cartão definitivo será enviado para sua
                        residência em algumas semanas.
                        </p>
                    </div>
                </div>

                <div className="flex-center gap-top">
                    <h2 className="blue-text">"Na Rádio Flor de Lótus, solidariedade e aprendizado se sintonizam em cada nota!"</h2>
                </div>

            </div>

        <Footer />
        
        </>
    )
}

export default HowCPF;